import classNames from "classnames"
import { observer } from "mobx-react"
import { getSnapshot } from "mobx-state-tree"
import { createRef } from "preact"
import { Suspense } from "preact/compat"
import { useEffect } from "preact/hooks"
import { Info, Layers, Settings, Upload } from "react-feather"
import {
	Button,
	FormControl,
	HiddenVisually,
	Link,
	Popover,
	Select,
	Switch,
	Tabs,
	Text,
	View,
	useTheme,
} from "reshaped"

import { AceEditor } from "#components/AceEditor"
import { modalsStore } from "#modals"

import { pipelineCreatePageStore } from "../page.model"
import css from "../page.module.css"

export const TransformerStep = observer(() => {
	const { colorMode } = useTheme()

	const fileInput = createRef<HTMLInputElement>()
	const editor = createRef()

	useEffect(() => {
		if (editor.current) {
			editor.current.editor.setOption(
				"wrap",
				pipelineCreatePageStore.editorSettings.softWrap,
			)

			editor.current.editor.setOption(
				"showInvisibles",
				pipelineCreatePageStore.editorSettings.showInvisibles,
			)
		}
	}, [editor])

	return (
		<>
			<View gap={4}>
				<View direction="row" align="center" justify="space-between">
					<View direction="row" align="center" gap={3}>
						<Text variant="title-5">Enter Transformer</Text>
						<Link
							href="https://docs.glassflow.dev/develop/define-a-transformation-function"
							attributes={{ target: "_blank" }}
							icon={Info}
							variant="plain"
						>
							Learn more about Transformers
						</Link>
					</View>
				</View>

				<View direction="row" gap={3} align="end">
					<FormControl>
						<FormControl.Label>Template</FormControl.Label>
						<Select
							value={
								pipelineCreatePageStore.transformerForm
									.selectedTemplate
							}
							name="template"
							onChange={({ value }) => {
								if (value !== "") {
									pipelineCreatePageStore.transformerForm.selectTemplate(
										value,
									)

									if (value === "aiAnomalyDetection") {
										pipelineCreatePageStore.transformerForm.setRequirements(
											"openai>=1.42.0",
										)
									}
								}
							}}
							options={[
								{
									disabled: true,
									label: "Custom",
									value: "",
								},
								{
									label: "Echo",
									value: "echo",
								},
								{
									label: "AI Anomaly Detection",
									value: "aiAnomalyDetection",
								},
								{
									label: "Mask IP Adresses",
									value: "maskIp",
								},
								{
									label: "PII Detection",
									value: "pii",
								},
								{
									label: "Filter Null Values",
									value: "removeNull",
								},
							]}
						/>
					</FormControl>

					<HiddenVisually>
						<input
							type="file"
							ref={fileInput}
							accept=".py"
							onChange={() => {
								const files = fileInput.current?.files

								if (files?.length) {
									const file = files[0]

									let reader = new FileReader()

									reader.addEventListener("load", (event) => {
										if (
											typeof event.target?.result ===
											"string"
										) {
											pipelineCreatePageStore.transformerForm.setHandler(
												event.target.result,
											)
										}
									})

									reader.readAsText(file, "UTF-8")
								}
							}}
						/>
					</HiddenVisually>
					<Button
						variant="outline"
						color="neutral"
						onClick={() => {
							fileInput.current?.click()
						}}
						icon={Upload}
					>
						Upload Handler File
					</Button>
					<View grow justify="end" direction="row" gap={3}>
						<Button
							variant="faded"
							color="primary"
							icon={Layers}
							onClick={() => {
								modalsStore.editEnvironmentVariables.openModal(
									getSnapshot(
										pipelineCreatePageStore.transformerForm
											.environmentVariables,
									),
									async (environmentVariables) => {
										pipelineCreatePageStore.transformerForm.setEnvironmentVariables(
											environmentVariables,
										)
									},
								)
							}}
						>
							Environment Variables
						</Button>

						<Popover position="bottom-end">
							<Popover.Trigger>
								{(attributes) => (
									<Button
										attributes={attributes}
										icon={Settings}
										variant="outline"
										color="neutral"
									/>
								)}
							</Popover.Trigger>
							<Popover.Content>
								<View direction="column" gap={4}>
									<Switch
										size="small"
										name="softWrap"
										checked={
											pipelineCreatePageStore
												.editorSettings.softWrap
										}
										onChange={({ checked }) => {
											pipelineCreatePageStore.editorSettings.setSoftWrap(
												checked,
											)
										}}
									>
										Soft Wrap
									</Switch>
									<Switch
										size="small"
										name="showInvisibles"
										checked={
											pipelineCreatePageStore
												.editorSettings.showInvisibles
										}
										onChange={({ checked }) => {
											pipelineCreatePageStore.editorSettings.setShowInvisibles(
												checked,
											)
										}}
									>
										Show Invisibles
									</Switch>
								</View>
							</Popover.Content>
						</Popover>
					</View>
				</View>

				<FormControl
					hasError={
						pipelineCreatePageStore.transformerForm.handler.hasError
					}
				>
					<Tabs>
						<Tabs.List>
							<Tabs.Item value="handler">handler.py</Tabs.Item>
							<Tabs.Item value="requirements">
								requirements.txt
							</Tabs.Item>
						</Tabs.List>
						<Tabs.Panel value="handler">
							<View
								minHeight="300px"
								height="60dvh"
								maxHeight="calc(100dvh - 500px)"
								paddingTop={3}
							>
								<Suspense fallback={null}>
									<AceEditor
										ref={editor}
										onLoad={(editorInstance) => {
											editorInstance.setOption(
												"wrap",
												pipelineCreatePageStore
													.editorSettings.softWrap,
											)

											editorInstance.setOption(
												"showInvisibles",
												pipelineCreatePageStore
													.editorSettings
													.showInvisibles,
											)
										}}
										className={classNames({
											[css.editor]: true,
											[css.editorError]:
												pipelineCreatePageStore
													.transformerForm.handler
													.hasError,
										})}
										mode="python"
										theme={
											colorMode === "dark"
												? "dracula"
												: "xcode"
										}
										onChange={(value) => {
											pipelineCreatePageStore.transformerForm.setHandler(
												value,
											)
											pipelineCreatePageStore.transformerForm.selectTemplate(
												"",
											)
										}}
										value={
											pipelineCreatePageStore
												.transformerForm.handler.value
										}
										lineHeight="1.65"
										width="100%"
										height="100%"
										fontSize={14}
										showGutter
										tabSize={4}
										enableBasicAutocompletion
										enableLiveAutocompletion
										showPrintMargin={false}
										editorProps={{ $blockScrolling: true }}
									/>
								</Suspense>

								<FormControl.Error>
									{
										pipelineCreatePageStore.transformerForm
											.handler.errorMessage
									}
								</FormControl.Error>
							</View>
						</Tabs.Panel>
						<Tabs.Panel value="requirements">
							<View
								minHeight="300px"
								height="60dvh"
								maxHeight="calc(100dvh - 500px)"
								paddingTop={3}
							>
								<Suspense fallback={null}>
									<AceEditor
										ref={editor}
										onLoad={(editorInstance) => {
											editorInstance.setOption(
												"wrap",
												pipelineCreatePageStore
													.editorSettings.softWrap,
											)

											editorInstance.setOption(
												"showInvisibles",
												pipelineCreatePageStore
													.editorSettings
													.showInvisibles,
											)
										}}
										className={classNames({
											[css.editor]: true,
											[css.editorError]:
												pipelineCreatePageStore
													.transformerForm
													.requirements.hasError,
										})}
										mode="python"
										theme={
											colorMode === "dark"
												? "dracula"
												: "xcode"
										}
										onChange={(value) => {
											pipelineCreatePageStore.transformerForm.setRequirements(
												value,
											)
											pipelineCreatePageStore.transformerForm.selectTemplate(
												"",
											)
										}}
										value={
											pipelineCreatePageStore
												.transformerForm.requirements
												.value
										}
										lineHeight="1.65"
										width="100%"
										height="100%"
										fontSize={14}
										showGutter
										tabSize={4}
										enableBasicAutocompletion
										enableLiveAutocompletion
										showPrintMargin={false}
										editorProps={{ $blockScrolling: true }}
									/>
								</Suspense>

								<FormControl.Error>
									{
										pipelineCreatePageStore.transformerForm
											.requirements.errorMessage
									}
								</FormControl.Error>
							</View>
						</Tabs.Panel>
					</Tabs>
				</FormControl>
			</View>

			<View gap={4} direction="row" paddingTop={6}>
				<View.Item columns={6}>
					<View align="end">
						<Button
							color="neutral"
							variant="faded"
							onClick={() => {
								pipelineCreatePageStore.previousStep()
							}}
						>
							Previous Step
						</Button>
					</View>
				</View.Item>

				<View.Item columns={6}>
					<View align="start">
						<Button
							color="primary"
							onClick={() => {
								pipelineCreatePageStore.nextStep()
							}}
						>
							Next Step
						</Button>
					</View>
				</View.Item>
			</View>
		</>
	)
})
