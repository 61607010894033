import { observer } from "mobx-react"
import { Info, Trash2 } from "react-feather"
import {
	Button,
	Card,
	FormControl,
	HiddenVisually,
	Link,
	Radio,
	RadioGroup,
	Select,
	Text,
	TextField,
	View,
} from "reshaped"

import { pipelineCreatePageStore } from "../page.model"

export const DataSinkStep = observer(() => {
	let details: React.JSX.Element | null = null

	if (pipelineCreatePageStore.sinkForm.connector.type === "webhook") {
		let headers = (
			<>
				<View paddingBottom={1}>
					<Text variant="body-3" weight="medium">
						Headers
					</Text>
				</View>

				<View gap={4}>
					{pipelineCreatePageStore.sinkForm.connector.meta.headers.map(
						({ name, value }, index) => {
							return (
								// biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
								<View gap={4}>
									<View gap={4} direction="row">
										<View direction="row" grow gap={4}>
											<View.Item columns={6}>
												<TextField
													name={`headerKey${index}`}
													placeholder="Name"
													value={name}
													onChange={({ value }) => {
														if (
															pipelineCreatePageStore
																.sinkForm
																.connector
																.type ===
															"webhook"
														) {
															pipelineCreatePageStore.sinkForm.connector.meta.setHeaderKey(
																index,
																value,
															)
														}
													}}
												/>
											</View.Item>
											<View.Item columns={6}>
												<TextField
													name={`headerValue${index}`}
													placeholder="Value"
													value={value}
													onChange={({ value }) => {
														if (
															pipelineCreatePageStore
																.sinkForm
																.connector
																.type ===
															"webhook"
														) {
															pipelineCreatePageStore.sinkForm.connector.meta.setHeaderValue(
																index,
																value,
															)
														}
													}}
												/>
											</View.Item>
										</View>

										<Button
											icon={Trash2}
											color="critical"
											variant="outline"
											onClick={() => {
												if (
													pipelineCreatePageStore
														.sinkForm.connector
														.type === "webhook"
												) {
													pipelineCreatePageStore.sinkForm.connector.meta.removeHeader(
														index,
													)
												}
											}}
										/>
									</View>
								</View>
							)
						},
					)}

					<View.Item columns={3}>
						<Button
							fullWidth
							color="positive"
							variant="faded"
							onClick={() => {
								if (
									pipelineCreatePageStore.sinkForm.connector
										.type === "webhook"
								) {
									pipelineCreatePageStore.sinkForm.connector.meta.addHeader()
								}
							}}
						>
							Add Header
						</Button>
					</View.Item>
				</View>
			</>
		)

		details = (
			<View gap={4} direction="column" paddingTop={6} key="webhook">
				<Text variant="title-6">Connector Details</Text>

				<View direction="row" wrap gap={4}>
					<View.Item columns={2}>
						<FormControl>
							<FormControl.Label>Method</FormControl.Label>
							<Select
								options={[
									{ label: "POST", value: "POST" },
									{ label: "GET", value: "GET" },
									{ label: "PUT", value: "PUT" },
									{ label: "PATCH", value: "PATCH" },
								]}
								name="method"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.method
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "webhook"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setMethod(
											event.value as
												| "POST"
												| "GET"
												| "PUT"
												| "PATCH",
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
						</FormControl>
					</View.Item>

					<View.Item columns={10}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sinkForm.connector.meta
									.url.hasError
							}
						>
							<FormControl.Label>URL</FormControl.Label>
							<TextField
								name="url"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.url.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "webhook"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setUrl(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sinkForm.connector
										.meta.url.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={12}>{headers}</View.Item>
				</View>
			</View>
		)
	} else if (
		pipelineCreatePageStore.sinkForm.connector.type === "clickhouse"
	) {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="webhook">
				<Text variant="title-6">Connector Details</Text>
				<View direction="row" wrap gap={4}>
					<View.Item columns={12}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sinkForm.connector.meta
									.addr.hasError
							}
						>
							<FormControl.Label>Address</FormControl.Label>
							<TextField
								name="addr"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.addr.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "clickhouse"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setAddress(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sinkForm.connector
										.meta.addr.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sinkForm.connector.meta
									.database.hasError
							}
						>
							<FormControl.Label>Database</FormControl.Label>
							<TextField
								name="database"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.database.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "clickhouse"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setDatabase(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sinkForm.connector
										.meta.database.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sinkForm.connector.meta
									.table.hasError
							}
						>
							<FormControl.Label>Table</FormControl.Label>
							<TextField
								name="table"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.table.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "clickhouse"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setTable(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sinkForm.connector
										.meta.table.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sinkForm.connector.meta
									.username.hasError
							}
						>
							<FormControl.Label>User Name</FormControl.Label>
							<TextField
								name="username"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.username.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "clickhouse"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setUsername(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sinkForm.connector
										.meta.username.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								pipelineCreatePageStore.sinkForm.connector.meta
									.password.hasError
							}
						>
							<FormControl.Label>Password</FormControl.Label>
							<TextField
								name="password"
								value={
									pipelineCreatePageStore.sinkForm.connector
										.meta.password.value
								}
								onChange={(event) => {
									if (
										pipelineCreatePageStore.sinkForm
											.connector.type === "clickhouse"
									) {
										pipelineCreatePageStore.sinkForm.connector.meta.setPassword(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
									type: "password",
								}}
							/>
							<FormControl.Error>
								{
									pipelineCreatePageStore.sinkForm.connector
										.meta.password.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	}

	return (
		<>
			<View paddingBottom={4} direction="row" gap={3} align="center">
				<Text variant="title-5">Select Data Sink</Text>
				<Link
					href="https://docs.glassflow.dev/concepts/consumer"
					attributes={{ target: "_blank" }}
					variant="plain"
					icon={Info}
				>
					Learn more about Data Sinks
				</Link>
			</View>
			<View direction="row" align="stretch" gap={4}>
				<RadioGroup
					name="source"
					onChange={(event) => {
						pipelineCreatePageStore.sinkForm.setType(
							event.value as "sdk" | "webhook",
						)
					}}
				>
					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sinkForm.connector
									.type === "sdk"
							}
						>
							<HiddenVisually>
								<Radio value="sdk" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									SDK
								</Text>
								<Text variant="body-3" color="neutral-faded">
									Consume the resulting events of this
									pipeline using the SDK.
								</Text>
							</View>
						</Card>
					</View.Item>

					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sinkForm.connector
									.type === "webhook"
							}
						>
							<HiddenVisually>
								<Radio value="webhook" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									Webhook
								</Text>
								<Text variant="body-3" color="neutral-faded">
									When a new event is processed, we will call
									your webhook.
								</Text>
							</View>
						</Card>
					</View.Item>

					<View.Item columns={6}>
						<Card
							as="label"
							height="100%"
							selected={
								pipelineCreatePageStore.sinkForm.connector
									.type === "clickhouse"
							}
						>
							<HiddenVisually>
								<Radio value="clickhouse" />
							</HiddenVisually>
							<View gap={3} direction="column" align="start">
								<Text variant="body-2" weight="medium">
									ClickHouse
								</Text>
								<Text variant="body-3" color="neutral-faded">
									ClickHouse is a real-time analytics DBMS.
								</Text>
							</View>
						</Card>
					</View.Item>
				</RadioGroup>
			</View>

			{details}

			<View gap={4} direction="row" paddingTop={6}>
				<View.Item columns={6}>
					<View align="end">
						<Button
							color="neutral"
							variant="faded"
							onClick={() => {
								pipelineCreatePageStore.previousStep()
							}}
						>
							Previous Step
						</Button>
					</View>
				</View.Item>

				<View.Item columns={6}>
					<View align="start">
						<Button
							color="primary"
							onClick={() => {
								pipelineCreatePageStore.nextStep()
							}}
						>
							Next Step
						</Button>
					</View>
				</View.Item>
			</View>
		</>
	)
})
