import * as Fathom from "fathom-client"
import { cast, flow, getSnapshot, types } from "mobx-state-tree"

import { AccessTokenModel, LogModel, PipelineModel, api } from "#api"

// #region AccessTokens

const AccessTokensModel = types
	.model("Access Tokens", {
		pipeline: types.maybe(types.reference(PipelineModel)),
		tokens: types.array(AccessTokenModel),
		isFetchingTokens: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const getTokens = flow(function* () {
			if (self.pipeline === undefined) {
				return
			}

			self.isFetchingTokens = true

			let tokens: Awaited<ReturnType<typeof api.listAccessTokens>> =
				yield api.listAccessTokens({
					queries: {
						page_size: 1000,
					},
					params: {
						pipeline_id: self.pipeline.id,
					},
				})

			self.tokens = cast(tokens.access_tokens)

			self.isFetchingTokens = false
		})

		return {
			getTokens,
		}
	})
	.views((self) => {
		return {
			get hasTokens() {
				return self.tokens.length > 0
			},
		}
	})

// #endregion

// #region Transformer

const TransformerModel = types
	.model("Transformer", {
		form: types.optional(
			types
				.model("Form", {
					currentHandler: types.optional(types.string, ""),
					currentRequirements: types.optional(types.string, ""),
					handler: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					requirements: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					environmentVariables: types.optional(
						types.array(
							types.model({
								name: types.string,
								value: types.string,
							}),
						),
						[{ name: "", value: "" }],
					),
					currentEnvironmentVariables: types.optional(
						types.array(
							types.model({
								name: types.string,
								value: types.string,
							}),
						),
						[{ name: "", value: "" }],
					),
				})
				.actions((self) => {
					return {
						validate() {
							if (
								self.handler.value === "" ||
								self.handler.value === undefined
							) {
								self.handler.hasError = true
								self.handler.errorMessage =
									"This field is required"
							} else {
								// Parse the Editor contents for a top level definition
								// of a function with the name `handler`
								let hasHandlerFunction = false

								const lines = self.handler.value.split("\n")

								for (let line of lines) {
									if (line.startsWith("def")) {
										if (
											line
												.slice(3)
												.trim()
												.startsWith("handler") &&
											line
												.slice(3)
												.trim()
												.slice(7)
												.trim()
												.startsWith("(")
										) {
											hasHandlerFunction = true
											break
										}
									}
								}

								if (!hasHandlerFunction) {
									self.handler.hasError = true
									self.handler.errorMessage =
										"You need to define the `handler` function."
								}
							}
						},
						setRequirements(requirements: string) {
							self.requirements.value = requirements
							self.requirements.hasError = false
						},
						setCurrentRequirements(requirements: string) {
							self.currentRequirements = requirements
						},
						setHandler(handler: string) {
							self.handler.value = handler
							self.handler.hasError = false
						},
						setCurrentHandler(handler: string) {
							self.currentHandler = handler
						},
						setEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}> | null,
						) {
							if (environmentVariables === null) {
								self.environmentVariables = cast([
									{ name: "", value: "" },
								])
							} else {
								self.environmentVariables =
									cast(environmentVariables)
							}
						},
						setCurrentEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}> | null,
						) {
							if (environmentVariables === null) {
								self.currentEnvironmentVariables = cast([
									{ name: "", value: "" },
								])
							} else {
								self.currentEnvironmentVariables =
									cast(environmentVariables)
							}
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							return self.handler.hasError
						},

						get hasChanges() {
							return (
								self.handler.value !== self.currentHandler ||
								self.requirements.value !==
									self.currentRequirements ||
								JSON.stringify(
									getSnapshot(self.environmentVariables),
								) !==
									JSON.stringify(
										getSnapshot(
											self.currentEnvironmentVariables,
										),
									)
							)
						},
					}
				}),
			{},
		),
		editorSettings: types.optional(
			types
				.model({
					showInvisibles: types.optional(types.boolean, false),
					softWrap: types.optional(types.boolean, true),
				})
				.actions((self) => {
					return {
						setShowInvisibles(value: boolean) {
							self.showInvisibles = value
						},

						setSoftWrap(value: boolean) {
							self.softWrap = value
						},
					}
				}),
			{},
		),
	})
	.actions((self) => {
		const fetchSourceFiles = flow(function* (pipelineId: string) {
			const result: Awaited<ReturnType<typeof api.getFunctionSource>> =
				yield api.getFunctionSource({
					params: {
						pipeline_id: pipelineId,
					},
				})

			if (result.files.length > 0) {
				self.form.setCurrentHandler(result.files[0].content)
				self.form.setHandler(result.files[0].content)
			} else {
				self.form.setCurrentHandler("")
				self.form.setHandler("")
			}

			if (result.files.length > 1) {
				const requirements = result.files[1].content

				self.form.setCurrentRequirements(requirements)
				self.form.setRequirements(requirements)
			} else {
				self.form.setCurrentRequirements("")
				self.form.setRequirements("")
			}
		})

		return {
			fetchSourceFiles,
		}
	})

// #endregion

// #region Logs

const LogsModel = types
	.model("Logs", {
		pipeline: types.maybe(types.reference(PipelineModel)),
		cursor: types.maybe(
			types.model("Cursor", {
				cursor: types.string,
				originalCursorTime: types.number,
			}),
		),
		logs: types.array(LogModel),
		fullscreenLogIndex: types.maybe(types.number),
		fetch: types.optional(
			types.union(
				types.model({ isFetching: types.literal(false) }),
				types.model({
					isFetching: types.literal(true),
					fetchingStyle: types.union(
						types.literal("filterChange"),
						types.literal("navigation"),
						types.literal("nextPage"),
						types.literal("autoRefresh"),
					),
				}),
			),
			{ isFetching: false },
		),
		lastFetch: types.optional(types.maybeNull(types.number), null),
		lastFetchWasForced: types.optional(types.boolean, false),
		filters: types.optional(
			types
				.model({
					severityLevel: types.optional(
						types.union(
							types.literal(100),
							types.literal(200),
							types.literal(400),
							types.literal(500),
						),
						200,
					),
					timeframe: types.optional(
						types.union(
							types.literal("oneHour"),
							types.literal("sixHours"),
							types.literal("oneDay"),
							types.literal("sevenDays"),
						),
						"oneHour",
					),
				})
				.actions((self) => {
					return {
						setSeverityLevel(level: 100 | 200 | 400 | 500) {
							Fathom.trackEvent(
								"Pipeline:Logs Filter:Severity Set",
							)
							Fathom.trackEvent(
								`Pipeline:Logs Filter:Severity Set=${level}`,
							)
							self.severityLevel = level
						},
						setTimeframe(
							timeframe:
								| "oneHour"
								| "sixHours"
								| "oneDay"
								| "sevenDays",
						) {
							Fathom.trackEvent(
								"Pipeline:Logs Filter:Timeframe Set",
							)
							Fathom.trackEvent(
								`Pipeline:Logs Filter:Timeframe Set=${timeframe}`,
							)
							self.timeframe = timeframe
						},
					}
				}),
			{},
		),
	})
	.actions((self) => {
		const getLogs = flow(function* (options: {
			fetchingStyle:
				| "filterChange"
				| "navigation"
				| "nextPage"
				| "autoRefresh"
				| "forcedRefresh"
			pageSize?: number
		}) {
			if (self.pipeline === undefined) {
				return
			}

			options.pageSize ??= 15

			let startTime: string | undefined
			let endTimeTimestamp: number

			if (options.fetchingStyle === "nextPage") {
				if (self.cursor === undefined || self.cursor.cursor === "") {
					return
				} else {
					endTimeTimestamp = self.cursor.originalCursorTime
				}
			} else if (
				options.fetchingStyle === "autoRefresh" ||
				options.fetchingStyle === "forcedRefresh"
			) {
				self.cursor = undefined
				endTimeTimestamp = Date.now()
			} else {
				self.cursor = undefined
				self.logs = cast([])
				endTimeTimestamp = Date.now()
			}

			if (options.fetchingStyle === "forcedRefresh") {
				self.lastFetchWasForced = true
			} else {
				self.lastFetchWasForced = false
			}

			// #region Date Logic
			if (self.filters.timeframe === "oneHour") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60,
				).toISOString()
			} else if (self.filters.timeframe === "sixHours") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 6,
				).toISOString()
			} else if (self.filters.timeframe === "oneDay") {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 24,
				).toISOString()
			} else {
				startTime = new Date(
					endTimeTimestamp - 1000 * 60 * 60 * 24 * 7,
				).toISOString()
			}
			// #endregion

			self.fetch = {
				isFetching: true,
				fetchingStyle:
					options.fetchingStyle === "forcedRefresh"
						? "autoRefresh"
						: options.fetchingStyle,
			}

			let result: Awaited<ReturnType<typeof api.getFunctionLogs>> =
				yield api.getFunctionLogs({
					queries: {
						page_size: options.pageSize,
						page_token: self.cursor?.cursor,
						severity_code: self.filters.severityLevel,
						start_time: startTime,
						end_time: new Date(endTimeTimestamp).toISOString(),
					},
					params: {
						pipeline_id: self.pipeline.id,
					},
				})

			if (self.cursor === undefined) {
				self.logs = cast([])
				self.cursor = {
					cursor: result.next,
					originalCursorTime: endTimeTimestamp,
				}
			} else {
				self.cursor.cursor = result.next
			}

			if (options.fetchingStyle === "autoRefresh") {
				self.logs = cast(result.logs.reverse())
			} else {
				self.logs = cast([...result.logs.reverse(), ...self.logs])
			}

			self.lastFetch = Date.now()

			self.fetch = { isFetching: false }
		})

		return {
			getLogs,
			showLogFullScreen(logIndex: number) {
				Fathom.trackEvent("Pipeline:Log Enter Fullscreen")
				self.fullscreenLogIndex = logIndex
				document.body.classList.add("preventBodyScroll")
			},
			closeFullScreen() {
				document.body.classList.remove("preventBodyScroll")
				self.fullscreenLogIndex = undefined
			},
		}
	})
	.views((self) => {
		return {
			get hasNoLogs() {
				return self.logs.length === 0
			},
			get hasLoadedFinalPage() {
				return self.cursor && self.cursor.cursor === ""
			},
			get hasMorePages() {
				return self.cursor && self.cursor.cursor !== ""
			},
		}
	})

// #endregion

// #region PipelineDetailPage

export const PipelineDetailPage = types
	.model("PipelineDetailPage", {
		pipeline: types.maybe(PipelineModel),
		accessTokens: types.optional(AccessTokensModel, {}),
		logs: types.optional(LogsModel, {}),
		transformer: types.optional(TransformerModel, {}),
		isSaving: types.optional(types.boolean, false),
	})
	.actions((self) => {
		return {
			getPipeline: flow(function* (options: {
				pipelineId: string
			}) {
				let pipeline: Awaited<ReturnType<typeof api.getPipeline>> =
					yield api.getPipeline({
						params: {
							pipeline_id: options.pipelineId,
						},
					})

				self.accessTokens.pipeline = undefined
				self.logs.pipeline = undefined

				self.pipeline = cast(pipeline)

				if (self.pipeline) {
					self.accessTokens.pipeline = self.pipeline
					self.accessTokens.getTokens()

					self.transformer.fetchSourceFiles(self.pipeline.id)

					if (self.pipeline.environments !== null) {
						self.transformer.form.setEnvironmentVariables(
							getSnapshot(self.pipeline.environments),
						)
						self.transformer.form.setCurrentEnvironmentVariables(
							getSnapshot(self.pipeline.environments),
						)
					} else {
						self.transformer.form.setEnvironmentVariables(null)
						self.transformer.form.setCurrentEnvironmentVariables(
							null,
						)
					}

					self.logs.pipeline = self.pipeline
					self.logs.filters = cast({})
					self.logs.getLogs({ fetchingStyle: "navigation" })
				}
			}),

			saveTransformer: flow(function* (callback: () => Promise<void>) {
				if (self.pipeline?.id) {
					self.transformer.form.validate()

					if (!self.transformer.form.hasError) {
						self.isSaving = true

						const result: Awaited<
							ReturnType<typeof api.patchPipeline>
						> = yield api.patchPipeline(
							{
								name: self.pipeline?.name,
								transformation_function:
									self.transformer.form.handler.value,
								requirements_txt:
									self.transformer.form.requirements.value,
								environments: getSnapshot(
									self.transformer.form.environmentVariables,
								).filter(({ name, value }) => {
									if (name === "" || value === "") {
										return false
									} else {
										return true
									}
								}),
							},
							{
								params: {
									pipeline_id: self.pipeline.id,
								},
							},
						)

						self.transformer.form.setCurrentHandler(
							self.transformer.form.handler.value,
						)

						self.transformer.form.setCurrentRequirements(
							self.transformer.form.requirements.value,
						)

						self.transformer.form.setCurrentEnvironmentVariables(
							getSnapshot(
								self.transformer.form.environmentVariables,
							),
						)

						yield callback()

						self.isSaving = false

						return result
					}
				}
			}),

			refreshPipeline: flow(function* () {
				if (self.pipeline?.id) {
					let pipeline: Awaited<ReturnType<typeof api.getPipeline>> =
						yield api.getPipeline({
							params: {
								pipeline_id: self.pipeline.id,
							},
						})

					self.pipeline = cast(pipeline)
				}
			}),
		}
	})
	.views((self) => {
		return {
			get sourceConnectorType(): string {
				if (self.pipeline?.metadata?.sourceConnector?.type) {
					return self.pipeline.metadata.sourceConnector.type
				} else if (self.pipeline?.source_connector?.kind) {
					return self.pipeline.source_connector.kind
				} else {
					return "sdk"
				}
			},
		}
	})

// #endregion

export const pipelineDetailPageStore = PipelineDetailPage.create()
