import { observer } from "mobx-react"
import { Button, Card, Text, View } from "reshaped"

import { pipelineCreatePageStore } from "../page.model"

export const ConfirmationStep = observer(() => {
	return (
		<>
			<View paddingBottom={4}>
				<Text variant="title-5">Overview</Text>
			</View>
			<View gap={6}>
				<View gap={4}>
					<View direction="row" gap={6}>
						<View.Item columns={6}>
							<Text variant="body-3" color="neutral-faded">
								Name
							</Text>
							<Text variant="body-3" weight="medium">
								{pipelineCreatePageStore.setupForm.name.value}
							</Text>
						</View.Item>

						<View.Item columns={4}>
							<Text variant="body-3" color="neutral-faded">
								Space
							</Text>
							<Text variant="body-3" weight="medium">
								{
									pipelineCreatePageStore.spaces[
										pipelineCreatePageStore.spaces.findIndex(
											(space) => {
												return (
													space.id ===
													pipelineCreatePageStore
														.setupForm.space.value
												)
											},
										)
									].name
								}
							</Text>
						</View.Item>
					</View>
				</View>

				<View direction="row" gap={6}>
					<View.Item columns={6}>
						<Card>
							<View gap={4}>
								<Text variant="body-3" color="neutral-faded">
									Data Source
								</Text>
								<Text variant="body-3" weight="medium">
									{pipelineCreatePageStore.sourceForm
										.connector.type === "webhook"
										? "Webhook"
										: pipelineCreatePageStore.sourceForm
													.connector.type ===
												"amazon_sqs"
											? "Amazon SQS"
											: pipelineCreatePageStore.sourceForm
														.connector.type ===
													"google_pubsub"
												? "Google Pub/Sub"
												: "SDK"}
								</Text>
							</View>
						</Card>
					</View.Item>

					<View.Item columns={6}>
						<Card>
							<View gap={4}>
								<Text variant="body-3" color="neutral-faded">
									Data Sink
								</Text>
								<Text variant="body-3" weight="medium">
									{pipelineCreatePageStore.sinkForm.connector
										.type === "webhook"
										? "Webhook"
										: pipelineCreatePageStore.sinkForm
													.connector.type ===
												"clickhouse"
											? "Clickhouse"
											: "SDK"}
								</Text>
							</View>
						</Card>
					</View.Item>
				</View>
			</View>

			<View gap={6} direction="row" paddingTop={10}>
				<View.Item columns={6}>
					<View align="end">
						<Button
							color="neutral"
							variant="faded"
							disabled={pipelineCreatePageStore.isCreating}
							onClick={() => {
								pipelineCreatePageStore.previousStep()
							}}
						>
							Previous Step
						</Button>
					</View>
				</View.Item>
				<View.Item columns={6}>
					<Button
						color="primary"
						loading={pipelineCreatePageStore.isCreating}
						onClick={async () => {
							pipelineCreatePageStore.nextStep()
						}}
					>
						Create Pipeline
					</Button>
				</View.Item>
			</View>
		</>
	)
})
