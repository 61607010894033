import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { Copy, Info, Plus, X } from "react-feather"
import {
	Actionable,
	Button,
	Card,
	Hidden,
	Skeleton,
	Text,
	Tooltip,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

import { AceEditor } from "#components/AceEditor"
import { pipelineDetailPageStore } from "../page.model"
import css from "./Details.module.css"

const TextSkeleton = () => <Skeleton height={2} width={40} />

export const Details = observer(() => {
	const toast = useToast()
	const { colorMode } = useTheme()

	return (
		<View gap={4}>
			<Card padding={4}>
				<View gap={12} direction="row">
					<View.Item columns={6}>
						<Text variant="body-3" color="neutral-faded">
							Space
						</Text>
						<Text variant="body-3" weight="medium">
							{pipelineDetailPageStore.pipeline?.space_name ? (
								pipelineDetailPageStore.pipeline.space_name
							) : (
								<TextSkeleton />
							)}
						</Text>
					</View.Item>

					<View.Item columns={6}>
						<Text variant="body-3" color="neutral-faded">
							Created on
						</Text>
						<Text variant="body-3" weight="medium">
							{pipelineDetailPageStore.pipeline?.created_at ? (
								Intl.DateTimeFormat("en", {
									dateStyle: "long",
									timeStyle: "medium",
								}).format(
									Date.parse(
										pipelineDetailPageStore.pipeline
											.created_at,
									),
								)
							) : (
								<TextSkeleton />
							)}
						</Text>
					</View.Item>
				</View>
			</Card>

			<View direction="row" gap={4}>
				<View.Item columns={6}>
					<Card>
						<View direction="row" gap={2} align="center">
							<Text variant="body-3" color="neutral-faded">
								Data Source
							</Text>
							<Button
								color="primary"
								variant="ghost"
								href="https://docs.glassflow.dev/develop/publish-data"
								attributes={{ target: "_blank" }}
								size="small"
							>
								Learn more
							</Button>
						</View>
						<Text variant="body-3" weight="medium">
							{pipelineDetailPageStore.sourceConnectorType ===
							"amazon_sqs"
								? "Amazon SQS"
								: pipelineDetailPageStore.sourceConnectorType ===
										"google_pubsub"
									? "Google Pub/Sub"
									: pipelineDetailPageStore.sourceConnectorType ===
											"webhook"
										? "Webhook"
										: "SDK"}
						</Text>
					</Card>
				</View.Item>

				<View.Item columns={6}>
					<Card>
						<View direction="row" gap={2} align="center">
							<Text variant="body-3" color="neutral-faded">
								Data Sink
							</Text>
							<Button
								color="primary"
								variant="ghost"
								href="https://docs.glassflow.dev/develop/consume-data"
								attributes={{ target: "_blank" }}
								size="small"
							>
								Learn more
							</Button>
						</View>
						{pipelineDetailPageStore.pipeline?.sink_connector
							?.kind === "webhook" ? (
							<View gap={2} direction="row" align="center">
								<Text variant="body-3" weight="medium">
									Webhook
								</Text>

								<Tooltip
									text={
										pipelineDetailPageStore.pipeline
											?.sink_connector?.config.url
									}
								>
									{(attributes) => (
										<Actionable attributes={attributes}>
											<Info size={14} />
										</Actionable>
									)}
								</Tooltip>
							</View>
						) : pipelineDetailPageStore.pipeline?.sink_connector
								?.kind === "clickhouse" ? (
							<View gap={2} direction="row" align="center">
								<Text variant="body-3" weight="medium">
									Clickhouse
								</Text>

								<Tooltip
									text={
										pipelineDetailPageStore.pipeline
											?.sink_connector?.config.addr
									}
								>
									{(attributes) => (
										<Actionable attributes={attributes}>
											<Info size={14} />
										</Actionable>
									)}
								</Tooltip>
							</View>
						) : (
							<Text variant="body-3" weight="medium">
								SDK
							</Text>
						)}
					</Card>
				</View.Item>
			</View>

			<Card>
				<View gap={8}>
					<View gap={4}>
						<Text variant="title-5">Getting Started</Text>

						<View direction="column" gap={4}>
							<View
								direction="row"
								justify="space-between"
								align="center"
							>
								<Text variant="title-6">Credentials</Text>
								<Button
									size="small"
									variant="outline"
									color="primary"
									onClick={async () => {
										if (
											pipelineDetailPageStore.pipeline &&
											pipelineDetailPageStore.accessTokens
												.tokens
										) {
											let envFileLines = [
												`PIPELINE_ID=${pipelineDetailPageStore.pipeline.id}`,
												`# Access Token "${pipelineDetailPageStore.accessTokens.tokens[0].name}"`,
												`PIPELINE_ACCESS_TOKEN=${pipelineDetailPageStore.accessTokens.tokens[0].token}`,
											]

											await navigator.clipboard.writeText(
												envFileLines.join("\n"),
											)

											const id = toast.show({
												color: "neutral",
												icon: Copy,
												title: "Copied Configuration File",
												actionsSlot: (
													<Button
														variant="ghost"
														icon={X}
														onClick={() =>
															toast.hide(id)
														}
													/>
												),

												timeout: "short",
												position: "bottom-end",
											})
										}
									}}
								>
									Copy as Configuration File
								</Button>
							</View>
							<View direction="row" gap={2} wrap>
								<View.Item columns={6}>
									<View gap={1}>
										<Text
											variant="body-3"
											color="neutral-faded"
										>
											Pipeline ID
										</Text>
										<Button
											variant="outline"
											endIcon={Copy}
											className={css.credential}
											onClick={async () => {
												if (
													pipelineDetailPageStore.pipeline
												) {
													await navigator.clipboard.writeText(
														pipelineDetailPageStore
															.pipeline.id,
													)

													const id = toast.show({
														color: "neutral",
														icon: Copy,
														title: "Copied Pipeline ID",
														actionsSlot: (
															<Button
																variant="ghost"
																icon={X}
																onClick={() =>
																	toast.hide(
																		id,
																	)
																}
															/>
														),

														timeout: "short",
														position: "bottom-end",
													})
												}
											}}
										>
											{pipelineDetailPageStore.pipeline ? (
												pipelineDetailPageStore.pipeline
													.id
											) : (
												<TextSkeleton />
											)}
										</Button>
									</View>
								</View.Item>

								<View.Item columns={6}>
									<View gap={1}>
										<Text
											variant="body-3"
											color="neutral-faded"
										>
											{pipelineDetailPageStore
												.accessTokens.tokens.length
												? `Access Token (${pipelineDetailPageStore.accessTokens.tokens[0].name})`
												: "Access Token"}
										</Text>
										{pipelineDetailPageStore.accessTokens
											.isFetchingTokens ? (
											<TextSkeleton />
										) : pipelineDetailPageStore.accessTokens
												.hasTokens ? (
											<Button
												variant="outline"
												endIcon={Copy}
												className={css.credential}
												onClick={async () => {
													if (
														pipelineDetailPageStore
															.accessTokens.tokens
															.length
													) {
														await navigator.clipboard.writeText(
															pipelineDetailPageStore
																.accessTokens
																.tokens[0]
																.token,
														)

														const id = toast.show({
															color: "neutral",
															icon: Copy,
															title: "Copied Access Token",
															actionsSlot: (
																<Button
																	variant="ghost"
																	icon={X}
																	onClick={() =>
																		toast.hide(
																			id,
																		)
																	}
																/>
															),

															timeout: "short",
															position:
																"bottom-end",
														})
													}
												}}
											>
												{`${pipelineDetailPageStore.accessTokens.tokens[0].token.slice(
													0,
													17,
												)}...${pipelineDetailPageStore.accessTokens.tokens[0].token.slice(
													-16,
												)}`}
											</Button>
										) : (
											<View paddingTop={1}>
												<Button
													variant="faded"
													color="positive"
													size="small"
													icon={Plus}
													onClick={() => {
														if (
															pipelineDetailPageStore.pipeline
														) {
															modalsStore.createAccessToken.openModal(
																pipelineDetailPageStore
																	.pipeline
																	.id,
																async () => {
																	await pipelineDetailPageStore.accessTokens.getTokens()
																},
															)
														}
													}}
												>
													Create New Access Token
												</Button>
											</View>
										)}
									</View>
								</View.Item>
							</View>
						</View>
					</View>
					<Hidden
						hide={
							pipelineDetailPageStore.sourceConnectorType !==
							"sdk"
						}
					>
						<View gap={2}>
							<Text variant="title-6">Source</Text>

							<Text>
								To get started with publishing events to your
								Pipeline, you can use this snippet.
							</Text>
							<Suspense fallback={null}>
								<AceEditor
									className={css.editor}
									mode="python"
									theme={
										colorMode === "dark"
											? "dracula"
											: "xcode"
									}
									value={`import glassflow

pipeline_client = glassflow.GlassFlowClient().pipeline_client(
	pipeline_id="${pipelineDetailPageStore.pipeline?.id}",
	pipeline_access_token="${
		pipelineDetailPageStore.accessTokens.hasTokens
			? pipelineDetailPageStore.accessTokens.tokens[0].token
			: ""
	}"
)
data = { "id": "123" }

pipeline_client.publish(data)`}
									lineHeight="1.65"
									width="100%"
									height="210px"
									fontSize={14}
									showGutter
									tabSize={4}
									readOnly
									enableBasicAutocompletion
									enableLiveAutocompletion
									showPrintMargin={false}
									editorProps={{ $blockScrolling: true }}
								/>
							</Suspense>
						</View>
					</Hidden>
					<Hidden
						hide={
							pipelineDetailPageStore.sourceConnectorType !==
							"webhook"
						}
					>
						<View gap={2}>
							<Text variant="title-6">Source</Text>
							<Text>
								To get started with publishing events to your
								Pipeline, you can use the Webhook URL specific
								to this pipeline.
							</Text>
							<Button
								variant="outline"
								endIcon={Copy}
								onClick={async () => {
									const webhookUrl = `https://api.glassflow.dev/v1/pipelines/${pipelineDetailPageStore.pipeline?.id}/topics/input/events`

									await navigator.clipboard.writeText(
										webhookUrl,
									)

									const id = toast.show({
										color: "neutral",
										icon: Copy,
										title: "Copied Webhook URL",
										actionsSlot: (
											<Button
												variant="ghost"
												icon={X}
												onClick={() => toast.hide(id)}
											/>
										),

										timeout: "short",
										position: "bottom-end",
									})
								}}
							>
								https://api.glassflow.dev/v1/pipelines/
								{pipelineDetailPageStore.pipeline?.id}
								/topics/input/events
							</Button>
							<Text>
								In order to use the webhook you need to send 2
								HTTP Headers:
							</Text>
							<Text>
								<span className={css.emphasis}>
									X-Pipeline-Access-Token
								</span>{" "}
								set to a valid Access Token and{" "}
								<span className={css.emphasis}>
									Content-Type
								</span>{" "}
								set to{" "}
								<span className={css.emphasis}>
									application/json
								</span>
								.
							</Text>
							<Text>
								Please note that the webhook only accepts{" "}
								<span className={css.emphasis}>POST</span>{" "}
								requests.
							</Text>
						</View>
					</Hidden>
					<Hidden
						hide={
							pipelineDetailPageStore.pipeline?.sink_connector
								?.kind === "webhook"
						}
					>
						<View gap={2}>
							<Text variant="title-6">Sink</Text>
							<Text>
								To get started with consuming events from your
								Pipeline, can use this snippet.
							</Text>
							<Suspense fallback={null}>
								<AceEditor
									className={css.editor}
									mode="python"
									theme={
										colorMode === "dark"
											? "dracula"
											: "xcode"
									}
									value={`import glassflow

pipeline_client = glassflow.GlassFlowClient().pipeline_client(
	pipeline_id="${pipelineDetailPageStore.pipeline?.id}",
	pipeline_access_token="${
		pipelineDetailPageStore.accessTokens.hasTokens
			? pipelineDetailPageStore.accessTokens.tokens[0].token
			: ""
	}"
)
response = pipeline_client.consume()

if response.status_code == 200:
	data = response.json()
	print("Consumed Data: ", data)`}
									lineHeight="1.65"
									width="100%"
									height="255px"
									fontSize={14}
									showGutter
									tabSize={4}
									readOnly
									enableBasicAutocompletion
									enableLiveAutocompletion
									showPrintMargin={false}
									editorProps={{ $blockScrolling: true }}
								/>
							</Suspense>
						</View>
					</Hidden>
				</View>
			</Card>
		</View>
	)
})
